import React from 'react';
import { graphql } from 'gatsby';

import Intro from '@/components/Intro';
import ProjectOverview from '@/components/ProjectOverview';
import Head from '@/components/Head';
import Grid from '@/atoms/Grid';

import { mapToSingleObject } from '@/utils/helpers';

export default ({ data: { frontpage, projectsOverview } }) => {
  const { introRaw } = mapToSingleObject(frontpage);
  const { projects } = mapToSingleObject(projectsOverview);

  return (
    <>
      <Head />

      <Grid.Container>
        <Intro blocksRaw={introRaw.text} mb={[2.5, 2.5, 3]} />

        <ProjectOverview projects={projects} />
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query {
    frontpage: allSanityFrontPageSettings {
      edges {
        node {
          introRaw: _rawIntro
        }
      }
    }
    projectsOverview: allSanityProjectOverviewSettings {
      edges {
        node {
          projects {
            title
            date(formatString: "MM.YY")
            id: _id
            client {
              title
            }
            slug {
              current
            }
            backdrop {
              ... on SanityVideoFile {
                id: _key
                type: _type
                videoFile {
                  asset {
                    url
                  }
                }
              }
              ... on SanityImageWithAlt {
                id: _key
                type: _type
                ...imageWithAltData
              }
            }
          }
        }
      }
    }
  }
`;
