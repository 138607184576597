import React, { useState } from 'react';

import ProjectList from './ProjectList';
import ProjectPreview from './ProjectPreview';

export default ({ projects, ...props }) => {
  const [activeProject, setActiveProject] = useState(null);
  // check for low power mode on iOS
  const [isAutoplayAvailable, setAutoplayAvailable] = useState(false);

  return (
    <>
      <ProjectPreview
        projects={projects}
        activeProject={activeProject}
        setAutoplayAvailable={setAutoplayAvailable}
      />

      <ProjectList
        projects={projects}
        setActiveProject={setActiveProject}
        activeProject={activeProject}
        isAutoplayAvailable={isAutoplayAvailable}
        {...props}
      />
    </>
  );
};
