import React from 'react';
import styled from 'styled-components';

import Image from '@/components/Image';
import FullscreenVideo from '@/components/FullscreenVideo';

const PreviewContainer = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const PreviewVideo = styled(FullscreenVideo)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.1s ease-out;
  opacity: ${props => (props.$visible ? 1 : 0)};
`;

const PreviewImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.1s ease-out;
  opacity: ${props => (props.$visible ? 1 : 0)};
`;

export default ({
  projects = [],
  activeProject = null,
  setAutoplayAvailable,
  ...props
}) => {
  return (
    <PreviewContainer {...props}>
      {projects.map(({ id, backdrop }) => {
        if (!backdrop.length) {
          return null;
        }

        if (backdrop[0].type === 'videoFile') {
          return (
            <PreviewVideo
              key={id}
              $visible={activeProject === id}
              play={activeProject === id}
              src={backdrop[0].videoFile.asset.url}
              poster={backdrop[0].placeholder}
              onPlay={() => setAutoplayAvailable(true)}
            />
          );
        }

        if (backdrop[0].type === 'imageWithAlt') {
          return (
            <PreviewImage
              key={id}
              $visible={activeProject === id}
              {...backdrop[0]}
            />
          );
        }

        return null;
      })}
    </PreviewContainer>
  );
};
