import React from 'react';
import styled, { css } from 'styled-components';

import Box from '@/atoms/Box';
import Image from '@/components/Image';
import VideoPlayer from '@/components/VideoPlayer';

/* object fit for IE & MS Edge */
const ObjectFitHack = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
`;

const Container = styled(Box)`
  overflow: hidden;
`;

const PosterImage = styled(Image)`
  z-index: -1;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FullscreenVideo = styled(VideoPlayer)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @supports (-ms-ime-align: auto) {
    ${ObjectFitHack}
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    ${ObjectFitHack}
  }
`;

export default ({ src, poster, onPlay, ...props }) => {
  return (
    <Container $position="relative" {...props}>
      <FullscreenVideo
        src={src}
        onPlay={onPlay}
        autoplay
        preload
        loop
        muted
        playsInline
      />

      <PosterImage {...poster} />
    </Container>
  );
};
