import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';

const Video = styled(Box).attrs({ as: 'video' })`
  display: block;
  width: 100%;
  height: auto;
`;

export default ({
  src,
  play = false,
  preload = false,
  autoplay = false,
  loop = false,
  poster,
  ...props
}) => {
  const videoRef = useRef(null);
  const [hasAutoplayed, setHasAutoplayed] = useState(false);
  const [isPlaying, setPlaying] = useState(false);

  // Video controls with promises
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const pauseVideo = useCallback(() => {
    if (isPlaying) {
      videoRef.current.pause();
    }
  }, [isPlaying, videoRef]);

  const playVideo = useCallback(() => {
    const playPromise = videoRef.current.play();

    if (playPromise !== undefined) {
      playPromise.then(() => {
        setPlaying(true);

        // make sure we still want to be playing the video when it started
        if (!play) {
          pauseVideo();
        }
      });
    }
  }, [play, pauseVideo, videoRef]);

  // video can be started and stopped by setting play true/false
  useEffect(() => {
    if (videoRef.current) {
      if (play) {
        playVideo();
      } else if (!autoplay || (autoplay && hasAutoplayed)) {
        pauseVideo();
        setHasAutoplayed(true);
      }
    }
  }, [play, autoplay, hasAutoplayed, pauseVideo, playVideo]);

  return (
    <Video
      ref={videoRef}
      poster={poster}
      autoPlay={autoplay}
      preload={preload ? 'auto' : 'metadata'}
      loop={loop}
      {...props}
    >
      <source type="video/mp4" src={src} />
    </Video>
  );
};
