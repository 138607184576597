import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import PortableText from '@/atoms/PortableText';

import { h1 } from '@/styles/typography';
import { scale } from '@/styles/utils';
import theme from '@/styles/theme';

const Intro = styled(Box)`
  ${h1}

  border-bottom: 1px solid ${theme.color.black};

  p {
    max-width: ${scale(50)};
  }
`;

export default ({ blocksRaw, ...props }) => {
  return (
    <Intro pb={[2.5, 2.5, 3]} {...props}>
      <PortableText blocks={blocksRaw} />
    </Intro>
  );
};
